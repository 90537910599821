/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import styles from './CustomCollage.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import arrowRight from '../../../images/white-arrow-right.svg';
import Slider from 'react-slick';
import cn from 'classnames';
import IconsBar from '../../shared/icons-bar/IconsBar';
import SlabInventoryPopUp from '../slab-inventory-popup/SlabInventoryPopUp';
import useFavourite from '../../../hooks/useFavourite';
import { FavoriteTypes } from '../../shared/enum/favorite-data-type';

import closeIcon from '../../../images/close-black.svg';
import Carousel from 'nuka-carousel';
import { Link } from 'gatsby';

interface Args {
  url: string;
  id: number;
  name: string;
}
interface Props {
  title?: string;
  head1: string;
  head2: string;
  head3: string;
  head4: string;
  head5: string;
  img1: string;
  img2: string;
  img3: string;
  img4: string;
  img5: string;
  PopUpToggle: () => void;
  setImageDetails: (arg: Args) => void;
  isPopUpShown: boolean;
}

const CustomCollage: React.FC<Props> = ({
  head1,
  head2,
  head3,
  head4,
  head5,
  img1,
  img2,
  img3,
  img4,
  img5,
  PopUpToggle,
  setImageDetails,
  isPopUpShown,
}: Props) => {
  const fetchedAutoUrl_1 = `${img1.split('upload')[0]}${'upload/f_auto'}${img1.split('upload')[1]}`;
  const fetchedAutoUrl_2 = `${img2.split('upload')[0]}${'upload/f_auto'}${img2.split('upload')[1]}`;
  const fetchedAutoUrl_3 = `${img3.split('upload')[0]}${'upload/f_auto'}${img3.split('upload')[1]}`;
  const fetchedAutoUrl_4 = `${img4.split('upload')[0]}${'upload/f_auto'}${img4.split('upload')[1]}`;
  const fetchedAutoUrl_5 = `${img5.split('upload')[0]}${'upload/f_auto'}${img5.split('upload')[1]}`;

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          //@ts-ignore
          width: window.innerWidth,
          //@ts-ignore
          height: window.innerHeight,
        });
      }

      // Add event listener
      window.addEventListener('resize', handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
  }

  const imgArr = [
    { url: fetchedAutoUrl_1, name: head1, id: 1 },
    { url: fetchedAutoUrl_2, name: head2, id: 2 },
    { url: fetchedAutoUrl_4, name: head4, id: 3 },
    { url: fetchedAutoUrl_3, name: head3, id: 4 },
    { url: fetchedAutoUrl_5, name: head5, id: 5 },
    { url: fetchedAutoUrl_1, name: head1, id: 6 },
    { url: fetchedAutoUrl_2, name: head2, id: 7 },
    { url: fetchedAutoUrl_4, name: head4, id: 8 },
    { url: fetchedAutoUrl_3, name: head3, id: 9 },
    { url: fetchedAutoUrl_5, name: head5, id: 10 },
    { url: fetchedAutoUrl_1, name: head1, id: 1 },
    { url: fetchedAutoUrl_2, name: head2, id: 2 },
    { url: fetchedAutoUrl_4, name: head4, id: 3 },
    { url: fetchedAutoUrl_3, name: head3, id: 4 },
    { url: fetchedAutoUrl_5, name: head5, id: 5 },
    { url: fetchedAutoUrl_1, name: head1, id: 6 },
    { url: fetchedAutoUrl_2, name: head2, id: 7 },
    { url: fetchedAutoUrl_4, name: head4, id: 8 },
    { url: fetchedAutoUrl_3, name: head3, id: 9 },
    { url: fetchedAutoUrl_5, name: head5, id: 10 },
    { url: fetchedAutoUrl_1, name: head1, id: 1 },
    { url: fetchedAutoUrl_2, name: head2, id: 2 },
    { url: fetchedAutoUrl_4, name: head4, id: 3 },
    { url: fetchedAutoUrl_3, name: head3, id: 4 },
    { url: fetchedAutoUrl_5, name: head5, id: 5 },
    { url: fetchedAutoUrl_1, name: head1, id: 6 },
    { url: fetchedAutoUrl_2, name: head2, id: 7 },
    { url: fetchedAutoUrl_4, name: head4, id: 8 },
    { url: fetchedAutoUrl_3, name: head3, id: 9 },
    { url: fetchedAutoUrl_5, name: head5, id: 10 },
    { url: fetchedAutoUrl_1, name: head1, id: 1 },
    { url: fetchedAutoUrl_2, name: head2, id: 2 },
    { url: fetchedAutoUrl_4, name: head4, id: 3 },
    { url: fetchedAutoUrl_3, name: head3, id: 4 },
    { url: fetchedAutoUrl_5, name: head5, id: 5 },
    { url: fetchedAutoUrl_1, name: head1, id: 6 },
    { url: fetchedAutoUrl_2, name: head2, id: 7 },
    { url: fetchedAutoUrl_4, name: head4, id: 8 },
    { url: fetchedAutoUrl_3, name: head3, id: 9 },
    { url: fetchedAutoUrl_5, name: head5, id: 10 },
  ];

  const imgArrMob = [
    { url: fetchedAutoUrl_1, name: head1, id: 1 },
    { url: fetchedAutoUrl_2, name: head2, id: 2 },
    { url: fetchedAutoUrl_4, name: head4, id: 3 },
    { url: fetchedAutoUrl_3, name: head3, id: 4 },
    { url: fetchedAutoUrl_5, name: head5, id: 5 },
  ];

  const [heightTrigger, setHeightTrigger] = useState<number | undefined>(0);
  const [index, setIndex] = useState<number>(0);
  const hookWidth = useWindowSize().width;

  useEffect(() => {
    setHeightTrigger(hookWidth);
  }, [hookWidth]);

  const [mobileIconsBarOpen, setMobileIconsBarOpen] = useState(false);
  const [imageData, setImageData] = useState({ url: '', id: 0, name: '' });

  const favoriteAPI = useFavourite();

  const [isFavoriteSaved, setIsFavoriteSaved] = React.useState(false);
  const setSavedFavorites = (isFavoriteSaved: boolean) => {
    setIsFavoriteSaved(isFavoriteSaved);
  };

  const targetRef = useRef();
  const [activeIndex, setActiveIndex] = useState(10);
  const halfwayIndex = Math.ceil(imgArr.length / 2);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  if (typeof window === 'undefined') {
    return null;
  }

  useLayoutEffect(() => {
    test_dimensions();
  }, [window.innerWidth]);

  const test_dimensions = () => {
    if (targetRef.current) {
      setDimensions({
        //@ts-ignore
        width: targetRef.current.offsetWidth,
        //@ts-ignore
        height: targetRef.current.offsetHeight,
      });
    }
  };

  // console.log(dimensions);
  const itemWidth = dimensions.width / 5.5;
  // const itemWidth = 754 / 5.1;
  const shuffleThreshold = halfwayIndex * itemWidth;
  const visibleStyleThreshold = shuffleThreshold / 2;

  const determinePlacement = (itemIndex: number) => {
    // If these match, the item is active
    if (activeIndex === itemIndex) return 0;

    if (itemIndex >= halfwayIndex) {
      if (activeIndex > itemIndex - halfwayIndex) {
        return (itemIndex - activeIndex) * itemWidth;
      } else {
        return -(imgArr.length + activeIndex - itemIndex) * itemWidth;
      }
    }

    if (itemIndex > activeIndex) {
      return (itemIndex - activeIndex) * itemWidth;
    }

    if (itemIndex < activeIndex) {
      if ((activeIndex - itemIndex) * itemWidth >= shuffleThreshold) {
        return (imgArr.length - (activeIndex - itemIndex)) * itemWidth;
      }
      return -(activeIndex - itemIndex) * itemWidth;
    }
  };

  useEffect(() => {
    typeof dimensions.width !== 'undefined' && dimensions.width > 0
      ? setHeightTrigger(dimensions.width)
      : null;
  }, [dimensions.width]);
  heightTrigger;

  console.log(heightTrigger);

  if (typeof window === 'undefined') {
    return null;
  }

  let prev: number;
  let next: number;
  if (index === 0) {
    prev = imgArr.length - 1;
  }
  if (index === imgArr.length - 1) {
    next = 0;
  }

  return (
    <div
      style={{ width: '150rem' }}
      //@ts-ignore
      ref={targetRef}
    >
      {isPopUpShown && (
        <>
          <div className={styles.customPagePopUp} onClick={PopUpToggle}></div>
          <div className={styles.popUp}>
            <div className={styles.iconsContainer}>
              <IconsBar
                imageUrl={imageData.url}
                specUrl={''}
                link={''}
                isFavoriteSaved={isFavoriteSaved}
              >
                <SlabInventoryPopUp
                  favouriteAPI={favoriteAPI}
                  title={''}
                  imagesPreview={imageData.url}
                  collectionId={imageData.id}
                  collectionType={FavoriteTypes.ALBUM}
                  setSavedFavorites={setSavedFavorites}
                />
              </IconsBar>
            </div>
            <img src={imageData.url} alt="image" className={styles.customImage} />
            <img src={closeIcon} alt="icon" className={styles.closeIcon} onClick={PopUpToggle} />
            <p className={styles.popUpImageName}>{imageData.name}</p>
          </div>
        </>
      )}

      <div className={cn(styles.carouselInner, 'hide-on-mobile')}>
        {imgArr.map((item, i) => (
          <>
            <div
              onClick={() => {
                setActiveIndex(i);
              }}
              className={cn(styles.carouselItem, {
                [styles.active]: activeIndex === i,
                [styles.visible]:
                  //@ts-ignore
                  Math.abs(determinePlacement(i)) <= visibleStyleThreshold,
              })}
              key={i}
              style={{
                transform: `translateX(${determinePlacement(i)}px)`,
              }}
            >
              <div className={cn(styles.borderedHidden, { [styles.bordered]: activeIndex === i })}>
                {activeIndex === i && (
                  <div className={styles.border1}>
                    {/* <p>custom fabrication</p> */}
                    <p
                      className={cn(styles.name, {
                        [styles.central]: activeIndex === i,
                      })}
                    >
                      {item.name}
                    </p>
                  </div>
                )}
              </div>
              <div
                className={cn(styles.coloredHidden, { [styles.colored]: activeIndex === i })}
                onClick={() => {
                  activeIndex === i && PopUpToggle();
                  activeIndex === i &&
                    setImageData({ url: item.url, id: item.id, name: item.name });
                }}
              >
                <p
                  style={{ color: '#363636' }}
                  className={cn(styles.name, {
                    [styles.central]: activeIndex === i,
                  })}
                >
                  {item.name}
                </p>
                <img src={item.url} />
              </div>
            </div>
          </>
        ))}
      </div>

      <div className={cn(styles.SlabCollection + ' slab_slider', 'hide-on-desktop')}>
        <h2 className={styles.head}>OUR SLAB COLLECTION</h2>
        <Carousel
          afterSlide={(i) => setIndex(i)}
          wrapAround
          slideWidth={'318px'} // pixels required because of Carousel lib
          height={'505px'}
          slideIndex={1}
          cellAlign={'center'}
          renderBottomCenterControls={() => <></>}
          renderCenterLeftControls={({ previousSlide }) => (
            <div className={styles.navBtn} onClick={previousSlide}></div>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <div className={styles.navBtn} onClick={nextSlide}></div>
          )}
        >
          {imgArrMob.map((s, i) => {
            return (
              <div className={'slab_slider__slide'} key={i}>
                <>
                  <div className={styles.slab_slider__numbering}>
                    {('0' + (i + 1)).slice(-2)}/{('0' + imgArrMob.length).slice(-2)}
                  </div>
                  <div
                    style={
                      i === next ? { left: '0' } : i === prev ? { right: '0', left: 'unset' } : {}
                    }
                    className={cn('slab_slider__slide__background_text', {
                      leftUnset: i === prev,
                    })}
                    dangerouslySetInnerHTML={{ __html: s.name }}
                  ></div>

                  <div className={'slab_slider__slide__name'}>{s.name}</div>
                  <div className={'slab_slider__slide__img-wrapper'}>
                    <img
                      className={styles.mobileSliderImage}
                      src={s.url}
                      alt="collection image"
                      onClick={() => {
                        setMobileIconsBarOpen(!mobileIconsBarOpen);
                        setImageDetails({ url: s.url, id: s.id, name: s.name });
                      }}
                    />
                  </div>

                  <div className={styles.swipeNote} style={{ marginTop: '1rem' }}>
                    <div>SWIPE TO SEE MORE</div>
                    <img src={arrowRight} alt="white arrow right" />
                  </div>
                </>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default CustomCollage;
