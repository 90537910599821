import React from 'react';
import styles from './ContactForm.module.scss';
import ReusableContactForm from '../../shared/contact-form/ReusableContactForm';

const ContactForm: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>CONTACT US</h1>
      <span className={styles.question}>Have a custom project you`d like to talk to us about?</span>
      <ReusableContactForm popUp={false} />
    </div>
  );
};

export default ContactForm;
