import { graphql, Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import SectionTitle from '../components/about/section-title/SectionTitle';
import ContactForm from '../components/custom/contact-form/ContactForm';
import CustomCollage from '../components/custom/custom-collage/CustomCollage';
import SlabInventoryPopUp from '../components/custom/slab-inventory-popup/SlabInventoryPopUp';
import StepCard from '../components/custom/step-card/StepCard';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import ArrowButton from '../components/shared/arrow-button/ArrowButton';
import Highlights from '../components/shared/highlights/Highlights';
import IconsBar from '../components/shared/icons-bar/IconsBar';
import { StrapiImage } from '../components/shared/interfaces';
import styles from './custom.module.scss';
import { FavoriteTypes } from '../components/shared/enum/favorite-data-type';
import useFavourite from '../hooks/useFavourite';
import PortfolioPopUpp from '../components/custom/portfolio-pop-upp/PortfolioPopUpp';
import cn from 'classnames';

export const query = graphql`
  query {
    strapi {
      customPage {
        title
        description
        subtitle
        galleryPreviewHead
        processPreviewHead
        processPreviewVideo
        galleryPreview {
          url
        }
        collageHead_1
        collageHead_2
        collageHead_3
        collageHead_4
        collageHead_5
        collageImg_1 {
          url
        }
        collageImg_2 {
          url
        }
        collageImg_3 {
          url
        }
        collageImg_4 {
          url
        }
        collageImg_5 {
          url
        }
      }
      customSteps {
        image {
          url
        }
        text
        title
      }
    }
  }
`;

interface Props {
  data: {
    strapi: {
      customPage: {
        title: string;
        description: string;
        subtitle: string;
        galleryPreviewHead: string;
        processPreviewHead: string;
        processPreviewVideo: string;
        galleryPreview: StrapiImage;
        collageHead_1: string;
        collageHead_2: string;
        collageHead_3: string;
        collageHead_4: string;
        collageHead_5: string;
        collageImg_1: StrapiImage;
        collageImg_2: StrapiImage;
        collageImg_3: StrapiImage;
        collageImg_4: StrapiImage;
        collageImg_5: StrapiImage;
      };
      customSteps: Array<{
        image: StrapiImage;
        text: string;
        title: string;
      }>;
    };
  };
}

const Custom: React.FC<Props> = ({ data: { strapi: data } }: Props) => {
  const [isPopUpShown, setPopUpIsShown] = useState(false);
  const favoriteAPI = useFavourite();
  const PopUpToggle = () => {
    setPopUpIsShown(!isPopUpShown);
  };

  const [isFavoriteSaved, setIsFavoriteSaved] = React.useState(false);
  const setSavedFavorites = (isFavoriteSaved: boolean) => {
    setIsFavoriteSaved(isFavoriteSaved);
  };

  const [mobileIconsBarOpen, setMobileIconsBarOpen] = useState(false);
  const [imageDetails, setImageDetails] = useState({ url: '', id: 0, name: '' });

  const TogglePopUp = () => {
    setMobileIconsBarOpen(!mobileIconsBarOpen);
  };

  const fetchedAutoUrl = `${
    data.customPage.galleryPreview.url.split('upload')[0]
  }${'upload/f_auto'}${data.customPage.galleryPreview.url.split('upload')[1]}`;

  const objForPopUp = [
    {
      id: imageDetails.id,

      Preview: {
        url: imageDetails.url,
        caption: imageDetails.name,
      },

      gallery: {
        name: imageDetails.name,
      },
    },
  ];

  return (
    <Layout lightNavbar={false}>
      {mobileIconsBarOpen && (
        <PortfolioPopUpp
          showPopup={mobileIconsBarOpen}
          albums={objForPopUp}
          setShowPopupToFalse={TogglePopUp}
          selectedIndexOfGallery={0}
        />
      )}
      <SEO title={data.customPage.title} />

      <section className={styles.topSection}>
        <div className={styles.darkContainer}>
          <Highlights
            title={data.customPage.title}
            description={data.customPage.description}
            subtitle={data.customPage.subtitle}
            onDark={false}
            subtitleWidth="23rem"
          />
        </div>
        <div className={cn(styles.collageContainer, 'hide-on-tablet')}>
          {typeof window !== 'undefined' && (
            <>
              <CustomCollage
                head1={data.customPage.collageHead_1}
                head2={data.customPage.collageHead_2}
                head3={data.customPage.collageHead_3}
                head4={data.customPage.collageHead_4}
                head5={data.customPage.collageHead_5}
                img1={data.customPage.collageImg_1.url}
                img2={data.customPage.collageImg_2.url}
                img3={data.customPage.collageImg_3.url}
                img4={data.customPage.collageImg_4.url}
                img5={data.customPage.collageImg_5.url}
                PopUpToggle={PopUpToggle}
                isPopUpShown={isPopUpShown}
                setImageDetails={setImageDetails}
              />

              <div
                className={styles.buttonContainer}
                style={{ position: 'relative', top: '3rem', right: '6rem' }}
              >
                <Link to="/portfolio/gallery/Custom">
                  <ArrowButton
                    isWhite={false}
                    name="SEE GALLERY"
                    fontSize={'1.6rem'}
                    imgHeight={'2.8rem'}
                    imgLeftMargin={'0rem'}
                  />
                </Link>
              </div>
            </>
          )}
        </div>

        <div
          className={cn(styles.collageContainer, 'hide-on-desktop')}
          onClick={() => setMobileIconsBarOpen(!mobileIconsBarOpen)}
        >
          <CustomCollage
            head1={data.customPage.collageHead_1}
            head2={data.customPage.collageHead_2}
            head3={data.customPage.collageHead_3}
            head4={data.customPage.collageHead_4}
            head5={data.customPage.collageHead_5}
            img1={data.customPage.collageImg_1.url}
            img2={data.customPage.collageImg_2.url}
            img3={data.customPage.collageImg_3.url}
            img4={data.customPage.collageImg_4.url}
            img5={data.customPage.collageImg_5.url}
            PopUpToggle={PopUpToggle}
            isPopUpShown={isPopUpShown}
            setImageDetails={setImageDetails}
          />

          <div className={styles.buttonContainer}>
            <Link to="/portfolio/gallery/Custom">
              <ArrowButton
                isWhite={true}
                name="SEE GALLERY"
                fontSize={'1.6rem'}
                imgHeight={'2.8rem'}
                imgLeftMargin={'3rem'}
              />
            </Link>
          </div>
        </div>
      </section>

      <section className={styles.stepsSection}>
        <div className={styles.divider}></div>
        <span className={styles.stepsTitle}>
          Hand Selected for You — <br />
          from Start to Finish
        </span>
        {/* <span
          style={{ listStyleType: 'disc' }}
          dangerouslySetInnerHTML={{ __html: newString }}
        ></span> */}
        <div className={styles.stepsContainer}>
          {data.customSteps.map((b, i) => (
            <StepCard
              {...b}
              imageSrc={b.image.url}
              index={(i + 1).toString()}
              key={`custom-step-${i}`}
            />
          ))}
        </div>
      </section>
      <section className={styles.gallerySection}>
        <div className={styles.previewContainer}>
          <div className={styles.preview}>
            <div className={styles.previewTitle}>
              <SectionTitle title={data.customPage.galleryPreviewHead} withLine={true} />
            </div>
            <img className={styles.content} src={fetchedAutoUrl} alt="gallery img preview" />
            <div className={styles.buttonContainer}>
              <Link to="/portfolio/gallery/Custom">
                <ArrowButton
                  isWhite={false}
                  name="SEE GALLERY"
                  fontSize={'1.6rem'}
                  imgHeight={'3rem'}
                  imgLeftMargin={'3rem'}
                />
              </Link>
            </div>
          </div>
          <div className={styles.preview}>
            <div className={styles.previewTitle + ' ' + styles.topBorder}>
              <SectionTitle title={data.customPage.processPreviewHead} withLine={true} />
            </div>
            <iframe
              className={styles.content}
              src={data.customPage.processPreviewVideo}
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </div>
      </section>
      <section className={styles.formSection}>
        <ContactForm></ContactForm>
      </section>
    </Layout>
  );
};

export default Custom;
