import React from 'react';

import styles from './StepCard.module.scss';
import OffsetBorderContainer from '../../shared/offset-border-container/OffsetBorderContainer';

interface Props {
  index: string;
  title: string;
  text: string;
  imageSrc: string;
}

const StepCard: React.FC<Props> = ({ index, title, text, imageSrc }: Props) => {
  const fetchedAutoUrl = `${imageSrc.split('upload')[0]}${'upload/f_auto'}${
    imageSrc.split('upload')[1]
  }`;
  return (
    <div className={styles.wrapper}>
      <span className={styles.name}>STEP</span>
      <div className={styles.contentWrapper}>
        <div className={styles.divider} />
        <div className={styles.contentContainer}>
          <h1 className={styles.index}>{index}</h1>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.imageContainer}>
            <OffsetBorderContainer offset={'1rem'}>
              <img className={styles.image} src={fetchedAutoUrl} alt={title} />
            </OffsetBorderContainer>
          </div>
          <div className={styles.descriptionContainer}>
            <span className={styles.text}> {text}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepCard;
